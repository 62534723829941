* {
	box-sizing: border-box;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100vw;
}
canvas {
	width: 100vw !important;
	height: 100vh !important;
	overflow: hidden;
}
