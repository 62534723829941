// RESPONSIVE (VIEWPORT SIZES)
$minWidth: 600px !default;
$maxWidth: 1200px !default;

// Basic Colors
$Dark: #222831; // My Black: rgb(34,40,49)
$MedDark: #393e46; // My Dark Gray: rgb(57,62,70)
$MedLight: #8e8e90; // My Gray: Silver: rgb(142,142,144)
$Light: #f7f7f7; // My White:(247,247,247)
$Danger: rgb(255, 59, 48); // Apple System Red Light
$Amazon: #ff9900;

// COLORS FIXED
$mainColor: hsl(3, 100%, 59.4%); // Apple System Red Light
$mainDark: hsl(3, 100%, 49%);
$background: rgba(255, 255, 255, 0.7);

// GENERAL SCSS

html {
	scroll-behavior: smooth;
	scroll-padding: 90px;
}
body {
	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
	max-width: $maxWidth;
	height: 100%;
}
